import React from "react";
import { Box, useTheme, Typography } from "@mui/material";

const LoadingImg = ({content}) => {
  const theme = useTheme();
  return (
    <Box
      height="80%"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      alignContent="center"
      top="30%"
      left="30%"
    >
      <Box>
        <svg
          width="475"
          height="267"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M114.751 165.435a14.45 14.45 0 01-10.227-4.246 14.52 14.52 0 01-4.237-10.25v-35.388a14.51 14.51 0 014.244-10.235 14.447 14.447 0 0110.22-4.237h242.793c3.832 0 7.507 1.524 10.219 4.237a14.511 14.511 0 014.245 10.235v35.388a14.52 14.52 0 01-4.237 10.25 14.45 14.45 0 01-10.227 4.246H114.751z"
            fill="#fff"
          />
          <path
            d="M157.412 166.734c4.253 2.18 6.478-1.884 4.722-4.287-1.046-1.413-4.121-5.804-7.859-8.851"
            fill="#fff"
          />
          <path
            d="M159.727 168.104a5.876 5.876 0 01-2.653-.708.75.75 0 01-.402-.722.757.757 0 01.079-.279.721.721 0 01.18-.227.732.732 0 01.818-.096c1.551.795 2.897.714 3.692-.221a2.42 2.42 0 00.094-2.966l-.301-.41c-1.218-1.666-4.073-5.568-7.427-8.303a.738.738 0 01-.243-.789.74.74 0 01.923-.5.727.727 0 01.256.136c3.502 2.855 6.435 6.864 7.688 8.577l.299.408a3.92 3.92 0 01-.16 4.812 3.619 3.619 0 01-2.843 1.288z"
            fill="#222433"
          />
          <path
            d="M319.036 165.569s-.371-6.121-.692-8.5c-.616-4.547-4.707-4.973-6.05-1.683-1.124-3.642-6.307-2.626-6.263 2.476l.007.824c-1.261-3.378-6.23-2.301-6.188 2.695.025 3.445.173 4.981.272 6.74a5.057 5.057 0 001.36 3.172l1.78 1.958a5.157 5.157 0 003.676 1.663 5.16 5.16 0 003.749-1.49 5.153 5.153 0 005.623-.402 5.175 5.175 0 004.358.551c1.904-.619 3.61-2.329 3.882-4.312l.074-2.998"
            fill="#fff"
          />
          <path
            d="M307.062 175.655a5.89 5.89 0 01-4.347-1.902l-1.782-1.96a5.833 5.833 0 01-1.552-3.626c-.02-.367-.043-.718-.066-1.081a89.131 89.131 0 01-.207-5.699c-.028-3.213 1.803-4.994 3.63-5.334.456-.09.925-.086 1.379.012.455.097.885.286 1.264.555.405-2.483 2.072-3.859 3.645-4.104a3.41 3.41 0 013.315 1.343 3.48 3.48 0 013.147-1.341c1.47.188 3.185 1.456 3.59 4.452.322 2.378.682 8.303.698 8.554a.74.74 0 01-.695.801.746.746 0 01-.786-.711c-.004-.061-.373-6.121-.687-8.445-.293-2.162-1.384-3.058-2.307-3.175-.962-.123-1.849.52-2.321 1.675a.746.746 0 01-.719.461.746.746 0 01-.676-.523c-.431-1.397-1.488-1.752-2.331-1.622-1.25.195-2.502 1.468-2.482 3.871l.007.823a.743.743 0 01-1.436.268c-.48-1.286-1.52-1.583-2.334-1.432-1.218.227-2.438 1.508-2.418 3.86.019 2.712.118 4.256.204 5.618.024.365.046.722.067 1.087a4.29 4.29 0 001.159 2.704l1.789 1.967a4.423 4.423 0 006.357.145.738.738 0 01.879-.12 4.413 4.413 0 004.817-.346.742.742 0 01.844-.035 4.424 4.424 0 003.733.471c1.723-.56 3.133-2.098 3.368-3.666l.073-2.955a.745.745 0 01.742-.725h.019a.739.739 0 01.722.762l-.074 2.998a.569.569 0 01-.007.083c-.294 2.151-2.099 4.173-4.387 4.918a5.907 5.907 0 01-4.55-.38 5.895 5.895 0 01-5.537.414 5.853 5.853 0 01-3.747 1.34z"
            fill="#222433"
          />
          <path
            d="M338.123 247.321l-.05-.347c-.717-7.335-6.428-13.555-13.821-15.364-9.296-.368-17.988-7.072-21.27-15.835-1.368.653-33.074 15.219-36.002 16.838l2.557 14.708h15.404l-2.275-12.316 9.667 12.291 45.79.025zM154.173 231.982h-10.187c-3.98 0-6.032-4.758-3.313-7.658l.524-.561c-1.042-.266-28.44-20.865-33.905-24.795L84.92 247.321h16.367l6.478-12.019 12.585 12.019 44.726.025v-.347c.544-7.162-4.005-13.233-10.903-15.017z"
            fill="#5A38FD"
          />
          <path
            d="M286.003 165.594l21.436 48.694-42.996 18.982-24.526-55.707-15.425-11.969"
            fill="#fff"
          />
          <path
            d="M264.443 234.014a.74.74 0 01-.679-.444l-24.45-55.534-15.276-11.853a.745.745 0 01.36-1.326.74.74 0 01.548.15l15.424 11.969a.746.746 0 01.225.288l24.227 55.028 41.638-18.383-21.137-48.015a.747.747 0 01.142-.814.741.741 0 011.215.214l21.438 48.694a.752.752 0 01.013.569.77.77 0 01-.156.245.726.726 0 01-.237.166l-42.996 18.983a.75.75 0 01-.299.063z"
            fill="#222433"
          />
          <path
            d="M197.934 165.594l-1.508 4.51a23.66 23.66 0 01-5.736 9.243l-47.174 46.365-38.248-27.854 22.746-23.194 5.078-9.112 64.842.042z"
            fill="#fff"
          />
          <path
            d="M143.517 226.456a.739.739 0 01-.436-.143l-38.248-27.853a.746.746 0 01-.093-1.123l22.676-23.124 5.029-9.024a.742.742 0 01.648-.381l64.843.042a.74.74 0 01.703.98l-1.508 4.51a24.556 24.556 0 01-5.915 9.532l-47.18 46.371a.74.74 0 01-.519.213zm-37.116-28.692l37.044 26.977 46.727-45.925a23.064 23.064 0 005.552-8.947l1.181-3.533-63.377-.041-4.865 8.731a.732.732 0 01-.118.158l-22.144 22.58z"
            fill="#222433"
          />
          <path
            d="M299.848 166.337H162.464a.739.739 0 01-.741-.743.745.745 0 01.741-.744h137.384a.741.741 0 01.742.744.74.74 0 01-.218.525.737.737 0 01-.524.218zM124.207 166.337h-9.89a15.203 15.203 0 01-10.747-4.469 15.273 15.273 0 01-4.459-10.771v-35.362a.743.743 0 111.484 0v35.362a13.785 13.785 0 004.023 9.72 13.721 13.721 0 009.699 4.033h9.89a.743.743 0 010 1.487zM264.394 142.647H126.482a.745.745 0 01-.525-1.269.741.741 0 01.525-.218h137.171v-14.051H126.482a.741.741 0 01-.742-.743.745.745 0 01.742-.744h137.912a.743.743 0 01.742.744v15.537a.743.743 0 01-.742.744z"
            fill="#222433"
          />
          <path
            d="M245.38 142.647a.743.743 0 01-.741-.744v-15.537a.745.745 0 01.741-.744.743.743 0 01.742.744v15.537a.743.743 0 01-.742.744zM226.343 142.647a.743.743 0 01-.741-.744v-15.537a.745.745 0 01.741-.744.743.743 0 01.742.744v15.537a.743.743 0 01-.742.744zM207.306 142.647a.741.741 0 01-.742-.744v-15.537a.746.746 0 01.742-.744.743.743 0 01.742.744v15.537a.743.743 0 01-.742.744zM188.269 142.647a.741.741 0 01-.742-.744v-15.537a.742.742 0 111.484 0v15.537a.743.743 0 01-.742.744zM169.23 142.647a.741.741 0 01-.742-.744v-15.537a.742.742 0 111.484 0v15.537a.741.741 0 01-.742.744zM150.193 142.647a.745.745 0 01-.742-.744v-15.537a.745.745 0 01.742-.744.741.741 0 01.742.744v15.537a.744.744 0 01-.742.744z"
            fill="#222433"
          />
          <path
            d="M344.155 125.622h-79.736a.743.743 0 00-.741.744v15.537c0 .411.332.744.741.744h79.736c.41 0 .742-.333.742-.744v-15.537a.743.743 0 00-.742-.744z"
            fill="#5A38FD"
          />
          <path
            d="M125.525 153.01a7.65 7.65 0 00-1.021 3.588 208.027 208.027 0 00-.297 10.879 7.008 7.008 0 001.558 4.411l2.25 2.776a7.005 7.005 0 007.145 2.378c1.072-.276 2.877-1.578 2.877-1.578a6.762 6.762 0 007.652.003 7.007 7.007 0 005.789 1.105c2.596-.669 5.068-2.825 5.588-5.477l.371-4.064-.028-.022c-.52-4.95-1.706-10.118-3.137-13.409-3.437-7.781-4.465-9.778-4.465-9.778"
            fill="#fff"
          />
          <path
            d="M133.46 177.997a7.763 7.763 0 01-6.022-2.865l-2.25-2.776a7.718 7.718 0 01-1.723-4.879c0-2.421.052-6.28.297-10.918a8.384 8.384 0 011.127-3.935.738.738 0 011.237-.057.74.74 0 01.032.829 6.905 6.905 0 00-.915 3.242 207.393 207.393 0 00-.297 10.839 6.243 6.243 0 001.394 3.945l2.248 2.773a6.268 6.268 0 006.388 2.127c.763-.197 2.154-1.122 2.625-1.462a.743.743 0 01.835-.022 6.018 6.018 0 006.836.011.74.74 0 01.846.012 6.241 6.241 0 005.176.989c2.476-.639 4.588-2.68 5.034-4.863l.35-3.838a.682.682 0 01-.009-.063c-.523-4.971-1.703-10.025-3.08-13.19-3.376-7.645-4.434-9.714-4.444-9.734a.742.742 0 011.319-.682c.042.082 1.077 2.106 4.483 9.818 1.42 3.262 2.634 8.407 3.18 13.466.04.106.056.22.046.334l-.371 4.064a.6.6 0 01-.011.074c-.544 2.774-3.064 5.263-6.132 6.053a7.705 7.705 0 01-5.986-.939 7.695 7.695 0 01-1.975.815 7.583 7.583 0 01-5.637-.817c-.632.425-1.84 1.186-2.72 1.412a7.653 7.653 0 01-1.881.237zM312.458 167.799a.742.742 0 01-.742-.732l-.165-11.669a.74.74 0 01.731-.754.74.74 0 01.752.732l.165 11.669a.736.736 0 01-.448.694.74.74 0 01-.283.06h-.01zM306.252 168.27a.74.74 0 01-.742-.725l-.215-8.84a.74.74 0 01.724-.762.741.741 0 01.76.725l.215 8.842a.743.743 0 01-.723.761l-.019-.001z"
            fill="#222433"
          />
          <path
            d="M277.501 101.625l-59.686-50.253a16.471 16.471 0 00-5.842-3.727 16.437 16.437 0 00-6.853-.993c-.212 1.13-2.423 12.39-8.922 12.303-2.788-.038-5.8-.098-7.87-1.987-2.011-1.833-2.885-4.572-3.025-7.236l-20.968 3.332a18.798 18.798 0 00-7.047 2.42l-58.324 26.87a17.311 17.311 0 00-5.886 4.44 17.383 17.383 0 00-1.408 20.763l30.658 47.406 32.587-9.442L131.6 105.5l26.199-12.068 4.581 7.676c2.107.065 56.89.684 60.463.455l-.01-4.86 4.718 3.972c1.905.1 41.657.811 49.95.95z"
            fill="#82B1FF"
          />
          <path
            d="M222.783 101.709a.743.743 0 01-.742-.744V73.582a.745.745 0 01.742-.743.74.74 0 01.741.743v27.383a.745.745 0 01-.741.744z"
            fill="#222433"
          />
          <path
            d="M190.177 35.905l-4.209 11.013a8.743 8.743 0 001.138 8.327l.445.594a8.688 8.688 0 003.576 2.803 8.667 8.667 0 008.69-1.133 8.692 8.692 0 002.741-3.627l2.868-7.085a12.996 12.996 0 008.041-4.343 13.056 13.056 0 003.209-8.57 13.457 13.457 0 00-.396-3.197 13.087 13.087 0 00-4.203-6.743c.247-1.013.371-2.052.371-3.095a13.427 13.427 0 00-.494-3.593l-14.826-.39-2.967 5.204-4.087.492c-5.362.644-7.287 7.453-3.06 10.822l3.163 2.521z"
            fill="#fff"
          />
          <path
            d="M194.531 60.07c-.443 0-.885-.031-1.323-.093a9.362 9.362 0 01-6.25-3.692l-.445-.594a9.462 9.462 0 01-1.236-9.038l4.015-10.505-2.738-2.183a6.84 6.84 0 01-2.319-7.217 6.837 6.837 0 012.133-3.335 6.816 6.816 0 013.62-1.59l3.716-.446 2.782-4.88a.741.741 0 01.663-.374l14.825.39a.744.744 0 01.696.545 13.924 13.924 0 01.228 6.62A13.75 13.75 0 01217 30.502c.274 1.106.414 2.241.419 3.38a13.84 13.84 0 01-3.261 8.887 13.78 13.78 0 01-8.202 4.698l-2.709 6.693a9.49 9.49 0 01-3.033 3.98 9.328 9.328 0 01-5.683 1.929zm3.022-42.449l-2.747 4.818a.734.734 0 01-.555.37l-4.088.491a5.35 5.35 0 00-4.498 3.855 5.363 5.363 0 001.812 5.647l3.165 2.522a.743.743 0 01.231.847l-4.211 11.013a7.974 7.974 0 001.04 7.617l.442.592a7.954 7.954 0 003.272 2.564 7.93 7.93 0 007.949-1.037 7.954 7.954 0 002.507-3.319l2.868-7.084a.744.744 0 01.588-.457 12.287 12.287 0 007.573-4.102 12.338 12.338 0 003.034-8.075 12.605 12.605 0 00-.374-3.02 12.278 12.278 0 00-3.964-6.352.748.748 0 01-.241-.744c.462-1.9.468-3.882.019-5.784l-13.822-.362z"
            fill="#222433"
          />
          <path
            d="M212.668 17.08a13.788 13.788 0 00-4.729-7.081 13.734 13.734 0 00-16.159-.624 13.786 13.786 0 00-5.259 6.695 8.52 8.52 0 00-.327.983 13.893 13.893 0 00-.522 3.771c0 .41.021.818.057 1.225.021 0 .039-.005.061-.005 1.396.002 2.813-.315 4.19.049 1.166.307 2.174 1.088 3.051 1.89 1.532 1.402 3.142 3.565 2.671 5.736a.732.732 0 00.552-.717v-6.964a4.026 4.026 0 011.174-2.837 4.006 4.006 0 012.831-1.177h11.695a.744.744 0 00.714-.945z"
            fill="#5A38FD"
          />
          <path
            d="M131.6 106.244a.736.736 0 01-.67-.425l-2.942-6.22a.743.743 0 011.34-.638l2.209 4.672 1.821-4.626a.733.733 0 01.394-.412.737.737 0 01.978.386.735.735 0 01.008.572l-2.448 6.219a.745.745 0 01-.665.471l-.025.001zM164.532 95.637a.732.732 0 01-.158-.017l-6.725-1.462a.742.742 0 01-.488-1.093.74.74 0 01.335-.309l8.382-3.865a.739.739 0 01.984.364.742.742 0 01-.364.986l-6.365 2.936 4.555.99a.741.741 0 01.578.806.744.744 0 01-.734.664zM357.111 166.337h-38.075a.74.74 0 01-.742-.743.745.745 0 01.742-.744h38.075a13.721 13.721 0 009.699-4.033 13.785 13.785 0 004.023-9.72V115.71a13.802 13.802 0 00-4.037-9.699 13.742 13.742 0 00-9.685-4.03h-74.654l-123.325-.116-.47.099a.735.735 0 01-.155.016h-19.464a.742.742 0 110-1.487h19.387l.469-.099a.735.735 0 01.155-.016l123.403.116h74.654a15.228 15.228 0 0110.732 4.465 15.298 15.298 0 014.474 10.748v35.39a15.275 15.275 0 01-4.459 10.771 15.203 15.203 0 01-10.747 4.469zM247.36 183.114a.742.742 0 01-.401-.118l-7.417-4.783a.755.755 0 01-.209-.201.745.745 0 01.453-1.151.74.74 0 01.558.101l7.418 4.783a.742.742 0 01.042 1.221.74.74 0 01-.444.148zM128.929 168.022a.742.742 0 01-.733-.633c-1.134-7.598-3.191-12.912-3.211-12.965a.743.743 0 011.381-.543c.087.221 2.136 5.513 3.297 13.288a.737.737 0 01-.734.853zM200.309 25.755c.751 0 1.36-.61 1.36-1.363 0-.752-.609-1.363-1.36-1.363-.751 0-1.36.61-1.36 1.363s.609 1.363 1.36 1.363zM210.94 25.755c.751 0 1.36-.61 1.36-1.363 0-.752-.609-1.363-1.36-1.363-.751 0-1.36.61-1.36 1.363s.609 1.363 1.36 1.363zM203.645 47.662c-2.87.012-5.67-.888-7.999-2.57a.745.745 0 01.315-1.336.742.742 0 01.553.13 12.091 12.091 0 007.131 2.289c.625 0 1.248-.046 1.866-.14a.74.74 0 01.838.623.743.743 0 01-.613.846c-.692.106-1.391.158-2.091.158zM209.579 30.043a.74.74 0 01-.109-.009l-4.649-.694a.745.745 0 01-.327-1.329.742.742 0 01.546-.141l3.407.508-1.887-5.09a.743.743 0 011.391-.517l2.324 6.27a.754.754 0 01-.087.683.738.738 0 01-.609.319z"
            fill="#222433"
          />
          <path
            d="M190.178 35.906a7.108 7.108 0 01-7.745-2.958 7.144 7.144 0 01-1.182-4.198 7.144 7.144 0 011.476-4.104 7.124 7.124 0 013.584-2.476 7.103 7.103 0 014.351.077 7.14 7.14 0 014.825 6.754"
            fill="#fff"
          />
          <path
            d="M188.366 36.882a7.85 7.85 0 01-4.368-1.328 7.876 7.876 0 01-2.896-3.537 7.9 7.9 0 011.705-8.588 7.853 7.853 0 0112.097 1.195 7.9 7.9 0 011.325 4.378.745.745 0 01-.742.743.743.743 0 01-.742-.743 6.395 6.395 0 00-1.194-3.713 6.358 6.358 0 00-10.222-.176 6.4 6.4 0 00-.265 7.424 6.358 6.358 0 006.925 2.65.74.74 0 01.881.536.743.743 0 01-.505.9 7.859 7.859 0 01-1.999.259zM208.4 36.814c-.065 0-.13-.004-.195-.012-.692-.084-1.117-.573-1.492-1.004-.512-.588-.755-.8-1.285-.69a.737.737 0 01-.771-.308.739.739 0 01.202-1.039.737.737 0 01.27-.11c1.428-.295 2.194.586 2.701 1.169.191.219.428.49.555.506.172-.117.325-.26.453-.426.468-.523 1.175-1.313 2.497-1.313a.743.743 0 010 1.486c-.629 0-.939.312-1.393.82-.37.414-.824.921-1.542.921z"
            fill="#222433"
          />
          <path
            d="M187.551 31.827a.741.741 0 01-.718-.928.735.735 0 01.233-.378l2.052-1.777-3.97-1.134a.743.743 0 01.406-1.43l5.291 1.512a.743.743 0 01.282 1.277l-3.09 2.676a.74.74 0 01-.486.182z"
            fill="#222433"
          />
          <path
            d="M404.304 174.723a3.837 3.837 0 003.839-3.835 3.837 3.837 0 00-3.839-3.835 3.837 3.837 0 00-3.839 3.835 3.837 3.837 0 003.839 3.835z"
            fill="#fff"
          />
          <path
            d="M404.305 175.466a4.584 4.584 0 01-4.234-2.826 4.58 4.58 0 015.987-5.981 4.575 4.575 0 011.485 7.464 4.59 4.59 0 01-3.238 1.343zm0-7.671a3.103 3.103 0 00-2.861 1.909 3.095 3.095 0 105.049-1.002 3.103 3.103 0 00-2.188-.907z"
            fill="#222433"
          />
          <path
            d="M406.287 161.337l-3.121 5.889-5.894-3.118a7.872 7.872 0 01-3.27-10.639l3.121-5.889 5.895 3.117a7.842 7.842 0 014.161 7.698 7.826 7.826 0 01-.892 2.942z"
            fill="#fff"
          />
          <path
            d="M403.164 167.968a.749.749 0 01-.347-.086l-5.895-3.118a8.626 8.626 0 01-4.198-5.082 8.614 8.614 0 01.62-6.56l3.121-5.889a.746.746 0 01.438-.363.757.757 0 01.566.054l5.895 3.117a8.586 8.586 0 014.554 8.425 8.581 8.581 0 01-.977 3.22l-3.12 5.886a.747.747 0 01-.657.396zm-5.734-19.385l-2.773 5.232a7.132 7.132 0 002.961 9.637l5.237 2.772 2.774-5.233.002-.003a7.085 7.085 0 00-.796-7.882 7.105 7.105 0 00-2.165-1.752l-5.24-2.771z"
            fill="#222433"
          />
          <path
            d="M441.43 160.347c-2.155-.594-7.207-6.21-7.207-6.21s7.232-2.202 9.386-1.609a4.062 4.062 0 012.824 4.999 4.061 4.061 0 01-5.003 2.82z"
            fill="#5A38FD"
          />
          <path
            d="M424.861 155.745c2.155.594 9.387-1.608 9.387-1.608s-5.053-5.592-7.207-6.211a4.059 4.059 0 10-2.18 7.819zM447.597 137.237l-11.368 9.699-4.706-14.178 16.074 4.479z"
            fill="#5A38FD"
          />
          <path
            d="M417.655 200.927a.743.743 0 01-.743-.743v-24.125c0-.196.078-.385.218-.524a.74.74 0 011.05 0c.14.139.218.328.218.524v24.125a.743.743 0 01-.743.743zM417.655 167.523a.746.746 0 01-.65-.38l-4.235-7.572a.747.747 0 01.066-.822.743.743 0 011.232.098l4.235 7.571a.738.738 0 01-.008.738.744.744 0 01-.64.367z"
            fill="#222433"
          />
          <path
            d="M417.655 167.523a.742.742 0 01-.743-.742v-9.007a.74.74 0 01.743-.742.742.742 0 01.743.742v6.157l2.844-5.084a.742.742 0 111.297.724l-4.235 7.572a.75.75 0 01-.649.38z"
            fill="#222433"
          />
          <path
            d="M409.927 174.352v-2.722a10.285 10.285 0 00-2.328-6.532l-1.115-1.361h22.315l-1.114 1.361a10.329 10.329 0 00-2.328 6.532v2.722a7.694 7.694 0 01-2.254 5.444 7.697 7.697 0 01-5.449 2.251 7.719 7.719 0 01-5.458-2.249 7.707 7.707 0 01-2.269-5.446z"
            fill="#82B1FF"
          />
          <path
            d="M413.287 200.927a.742.742 0 01-.71-.525l-12.898-42.064a.743.743 0 111.421-.435l12.898 42.064a.75.75 0 01-.113.659.74.74 0 01-.598.301zM397.122 148.322a.742.742 0 01-.711-.528l-1.535-5.072a.73.73 0 01.049-.572.726.726 0 01.443-.366.75.75 0 01.796.248.732.732 0 01.134.26l1.536 5.073a.747.747 0 01-.378.878.743.743 0 01-.334.079z"
            fill="#222433"
          />
          <path
            d="M397.123 148.322a.746.746 0 01-.561-.255l-3.492-4.008a.75.75 0 01-.184-.54.758.758 0 01.254-.51.745.745 0 011.05.075l3.493 4.008a.745.745 0 01-.56 1.23z"
            fill="#222433"
          />
          <path
            d="M397.123 148.322a.742.742 0 01-.718-.547.741.741 0 01-.02-.289l.669-5.27a.74.74 0 01.83-.643.746.746 0 01.644.83l-.669 5.27a.744.744 0 01-.736.649z"
            fill="#222433"
          />
          <path
            d="M416.86 246.974a16.426 16.426 0 01-11.611-4.805 16.394 16.394 0 010-23.2 16.43 16.43 0 0111.611-4.805h1.461a16.43 16.43 0 0111.611 4.805 16.394 16.394 0 010 23.2 16.426 16.426 0 01-11.611 4.805h-1.461z"
            fill="#fff"
          />
          <path
            d="M418.322 247.717h-1.461a17.173 17.173 0 01-12.137-5.023 17.14 17.14 0 01-5.027-12.125c0-4.547 1.809-8.909 5.027-12.125a17.176 17.176 0 0112.137-5.022h1.461c4.552 0 8.918 1.807 12.136 5.022a17.137 17.137 0 010 24.25 17.169 17.169 0 01-12.136 5.023zm-1.461-32.81c-4.158 0-8.146 1.65-11.086 4.587a15.657 15.657 0 000 22.151 15.686 15.686 0 0011.086 4.587h1.461c4.158 0 8.146-1.65 11.086-4.587a15.657 15.657 0 000-22.151 15.686 15.686 0 00-11.086-4.587h-1.461z"
            fill="#222433"
          />
          <path
            d="M417.604 213.768l-10.526-13.584h21.052l-10.526 13.584z"
            fill="#fff"
          />
          <path
            d="M417.605 214.511a.737.737 0 01-.588-.288l-10.526-13.584a.745.745 0 01.588-1.197h21.052a.747.747 0 01.667.415.747.747 0 01-.08.782l-10.526 13.584a.733.733 0 01-.587.288zm-9.012-13.584l9.012 11.629 9.011-11.629h-18.023z"
            fill="#222433"
          />
          <path
            d="M421.399 200.927a.745.745 0 01-.716-.941l15.166-54.458a.746.746 0 01.626-.538.744.744 0 01.806.936l-15.166 54.456a.74.74 0 01-.716.545z"
            fill="#222433"
          />
          <path
            d="M54.282 142.316l-6.493 6.121-6.145-6.47a10.507 10.507 0 01-2.878-7.527 10.513 10.513 0 013.303-7.352l6.494-6.121 6.144 6.469a10.511 10.511 0 012.878 7.528 10.514 10.514 0 01-3.303 7.352z"
            fill="#fff"
          />
          <path
            d="M47.789 149.183a.755.755 0 01-.545-.233l-6.144-6.47a11.262 11.262 0 01-3.08-8.062 11.268 11.268 0 013.536-7.874l6.492-6.119a.749.749 0 011.059.029l6.144 6.469a11.263 11.263 0 013.08 8.061 11.266 11.266 0 01-3.534 7.874l-6.494 6.121a.75.75 0 01-.514.204zm.745-27.161l-5.95 5.608a9.775 9.775 0 00-3.067 6.832 9.77 9.77 0 002.673 6.994l5.628 5.925 5.95-5.607v-.002a9.773 9.773 0 003.065-6.831 9.768 9.768 0 00-2.673-6.993l-5.626-5.926z"
            fill="#222433"
          />
          <path
            d="M47.616 158.688c2.841 0 5.145-2.295 5.145-5.125 0-2.831-2.304-5.126-5.145-5.126-2.842 0-5.145 2.295-5.145 5.126 0 2.83 2.303 5.125 5.145 5.125z"
            fill="#fff"
          />
          <path
            d="M47.615 159.435a5.912 5.912 0 01-3.275-.99 5.875 5.875 0 01-2.17-2.635 5.852 5.852 0 011.277-6.4 5.906 5.906 0 016.423-1.273 5.898 5.898 0 012.646 2.163c.647.966.993 2.101.993 3.263a5.87 5.87 0 01-1.728 4.15 5.913 5.913 0 01-4.166 1.722zm0-10.252a4.39 4.39 0 00-4.061 2.704 4.363 4.363 0 00.953 4.772 4.398 4.398 0 004.79.949 4.37 4.37 0 002.714-4.045 4.378 4.378 0 00-1.29-3.096 4.412 4.412 0 00-3.106-1.284zM41.987 200.665a.749.749 0 01-.722-.548l-12.58-45.037a.744.744 0 01.521-.919.754.754 0 01.924.519l12.579 45.037a.74.74 0 01-.069.568.76.76 0 01-.452.352.805.805 0 01-.201.028z"
            fill="#222433"
          />
          <path
            d="M24.812 166.651c1.898-.523 6.344-5.45 6.344-5.45s-6.37-1.94-8.267-1.393a3.568 3.568 0 00-2.134 1.682 3.543 3.543 0 001.356 4.827 3.58 3.58 0 002.7.334z"
            fill="#fff"
          />
          <path
            d="M23.829 167.537a4.325 4.325 0 01-2.835-1.068 4.29 4.29 0 01-.756-5.605 4.323 4.323 0 012.452-1.776c2.044-.589 8.011 1.195 8.685 1.4a.75.75 0 01.503.514.742.742 0 01-.165.699c-.47.52-4.645 5.098-6.696 5.668a4.3 4.3 0 01-1.188.168zm-.048-7.084c-.23-.007-.46.017-.683.072a2.793 2.793 0 00-1.716 1.337 2.743 2.743 0 00-.246 2.118 2.787 2.787 0 001.343 1.709 2.762 2.762 0 002.125.245l.009-.003c1.049-.288 3.453-2.548 5.17-4.349-2.009-.553-4.594-1.129-6.002-1.129z"
            fill="#222433"
          />
          <path
            d="M39.423 162.62c-1.898.522-8.267-1.394-8.267-1.394s4.446-4.926 6.344-5.449a3.59 3.59 0 012.72.316 3.552 3.552 0 011.799 3.529 3.54 3.54 0 01-1.348 2.374c-.371.288-.795.5-1.248.624z"
            fill="#fff"
          />
          <path
            d="M38.584 163.45c-2.544 0-7.064-1.334-7.645-1.509a.754.754 0 01-.505-.514.742.742 0 01.164-.7c.47-.521 4.652-5.105 6.702-5.669a4.333 4.333 0 013.278.396c.996.56 1.73 1.49 2.04 2.588a4.202 4.202 0 01-.38 3.252 4.274 4.274 0 01-2.616 2.045c-.34.082-.689.119-1.038.111zm-6.052-2.607c2.408.654 5.643 1.346 6.69 1.057a2.804 2.804 0 001.708-1.334 2.74 2.74 0 00.245-2.118 2.814 2.814 0 00-1.332-1.693 2.832 2.832 0 00-2.144-.259c-1.047.289-3.45 2.546-5.166 4.347z"
            fill="#222433"
          />
          <path
            d="M16.695 144.058l12.713 10.823 5.295-15.825-18.008 5.002z"
            fill="#fff"
          />
          <path
            d="M29.407 155.628a.754.754 0 01-.487-.179l-12.712-10.824a.745.745 0 01.285-1.287l18.007-5.001a.756.756 0 01.744.205.746.746 0 01.168.75l-5.294 15.825a.748.748 0 01-.71.511zM18.251 144.4l10.797 9.194 4.497-13.441-15.294 4.247zM51.136 200.665a.747.747 0 01-.703-1.005l10.59-28.689a.746.746 0 01.675-.504.76.76 0 01.718.44.747.747 0 01.014.579l-10.59 28.689a.75.75 0 01-.704.49zM66.94 158.15a.753.753 0 01-.686-.446l-3.017-6.826a.746.746 0 01.958-.997.75.75 0 01.414.395l3.017 6.827a.741.741 0 01-.329.957.753.753 0 01-.357.09z"
            fill="#222433"
          />
          <path
            d="M66.94 158.15a.76.76 0 01-.612-.314.746.746 0 01-.097-.678l2.353-6.728a.749.749 0 011.415.492l-1.736 4.963 4.857-2.488a.747.747 0 01.572-.047.757.757 0 01.437.369.752.752 0 01.047.57.74.74 0 01-.37.436l-6.525 3.341a.756.756 0 01-.342.084z"
            fill="#222433"
          />
          <path
            d="M53.734 166.128l1.124-3.06a12.185 12.185 0 00.075-8.311l-.7-1.991 25.126 9.207-1.824 1.07a12.405 12.405 0 00-5.344 6.37l-1.124 3.06a9.234 9.234 0 01-4.796 5.18 9.285 9.285 0 01-7.068.294 9.24 9.24 0 01-5.185-4.784 9.189 9.189 0 01-.284-7.035z"
            fill="#82B1FF"
          />
          <path
            d="M46.271 200.665h-.022a.75.75 0 01-.727-.768l1.716-58.991a.746.746 0 01.77-.725.752.752 0 01.728.768L47.02 199.94a.747.747 0 01-.749.725zM48.564 121.713h-.025a.75.75 0 01-.724-.769l.225-7.116a.75.75 0 01.75-.722h.024a.75.75 0 01.724.769l-.225 7.117a.747.747 0 01-.749.721z"
            fill="#222433"
          />
          <path
            d="M48.564 121.713a.746.746 0 01-.693-.461l-2.723-6.569a.745.745 0 01.407-.975.75.75 0 01.979.405l2.722 6.569a.746.746 0 01-.692 1.031z"
            fill="#222433"
          />
          <path
            d="M48.563 121.713a.75.75 0 01-.747-.704.747.747 0 01.072-.366l3.097-6.394a.748.748 0 011.015-.381.755.755 0 01.388.443.742.742 0 01-.054.585l-3.097 6.394a.748.748 0 01-.674.423z"
            fill="#222433"
          />
          <path
            d="M45.092 246.97a16.593 16.593 0 01-11.709-4.831 16.467 16.467 0 01-4.85-11.666c0-4.375 1.745-8.571 4.85-11.665a16.593 16.593 0 0111.709-4.831h1.473c4.392 0 8.604 1.738 11.71 4.831a16.467 16.467 0 014.85 11.665c0 4.376-1.745 8.572-4.85 11.666a16.593 16.593 0 01-11.71 4.831h-1.473z"
            fill="#fff"
          />
          <path
            d="M46.565 247.717H45.09a17.34 17.34 0 01-12.238-5.051 17.208 17.208 0 01-5.07-12.193c0-4.573 1.824-8.959 5.07-12.192a17.34 17.34 0 0112.238-5.051h1.474a17.34 17.34 0 0112.238 5.051 17.208 17.208 0 015.07 12.192c0 4.574-1.824 8.96-5.07 12.193a17.34 17.34 0 01-12.238 5.051zm-1.474-32.994a15.838 15.838 0 00-11.179 4.613 15.721 15.721 0 00-4.63 11.137c0 4.178 1.665 8.184 4.63 11.138a15.842 15.842 0 0011.18 4.613h1.473c4.193 0 8.214-1.66 11.179-4.613a15.721 15.721 0 004.63-11.138 15.72 15.72 0 00-4.63-11.137 15.838 15.838 0 00-11.18-4.613h-1.473z"
            fill="#222433"
          />
          <path
            d="M45.816 213.578l10.614-13.66H35.201l10.615 13.66z"
            fill="#fff"
          />
          <path
            d="M45.817 214.325a.754.754 0 01-.593-.29l-10.614-13.66a.745.745 0 01.592-1.203h21.23a.75.75 0 01.745.822.741.741 0 01-.153.381l-10.615 13.66a.75.75 0 01-.592.29zm-9.087-13.66l9.087 11.694 9.087-11.694H36.73zM434.235 248.153H27.823c-.427 0-.753-.339-.753-.781 0-.443.326-.781.753-.781h406.412c.426 0 .752.338.752.781 0 .442-.326.781-.752.781z"
            fill="#222433"
          />
        </svg>
      </Box>
      <Box>
        <Typography
          variant="h4"
          fontWeight="700"
          sx={{ color: theme.palette.font.main }}
        >
          {content}
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingImg;
